/* XmasUI */

.hues-m-controls.hues-x-controls {
    z-index: 1;
}

.hues-x-snow {
    z-index: -9;
}

.hues-m-beatbar.hues-x-beatbar {
    background: none;
    border-style: none;
    overflow: visible;
}

.hues-x-light {
    position: absolute;
    width: 68px;
    height: 113px;
    transform-origin: 32px 69px;
    background-image: url("../../img/lightbase.png");
    background-position: 0 0;
    background-repeat: no-repeat;
}
.inverted .hues-x-light {
    background-position: -68px 0;
}

.hues-x-fade{
    transition: opacity 0.1s linear;
}

.hues-x-lighton, .hues-x-lightoff {
    position: absolute;
    width: 56px;
    height: 81px;
    left: 5px;
    top: 9px;
    background-repeat: no-repeat;
}

.hues-x-lighton {
    opacity: 1;
    background-image: url("../../img/lighton.png");
}
.inverted .hues-x-lighton {
    background-image: url("../../img/lighton_inverted.png");
}

.hues-x-lightoff {
    opacity: 0;
    background-image: url("../../img/lightoff.png");
}
.inverted .hues-x-lightoff {
    background-image: url("../../img/lightoff_inverted.png");
}

.hues-x-lighton.off{
    opacity: 0;
}

.hues-x-lightoff.off{
    opacity: 1;
}

.hues-x-wiresleft, .hues-x-wiresbottom, .hues-x-wiresright {
    position: absolute;
}

.hues-x-wiresleft, .hues-x-wiresright {
    height: 100%;
    width: 200px;
    top: 0;
    overflow: hidden;
}

.hues-x-wiresleft {
    left: 0;
}
.hues-x-wiresleft::before {
    width: 60px;
    height: 1435px;
    background-image: url("../../img/wiresleft.png");
}
.inverted .hues-x-wiresleft::before {
    background-position: -60px 0;
}

.hues-x-wiresright {
    right: 0;
}
.hues-x-wiresright::before {
    right: 0;
    width: 58px;
    height: 1261px;
    background-image: url("../../img/wiresright.png");
}
.inverted .hues-x-wiresright::before {
    background-position: -58px 0;
}

.hues-x-wiresbottomhelper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.hues-x-wiresbottom {
    height: 200px;
    width:2621px;
    left:50%;
    margin-left: -1310.5px;
    overflow: hidden;
}
.hues-x-wiresbottom::before {
    bottom: 0;
    width: 2621px;
    height: 49px;
    background-image: url("../../img/wiresbottom.png");
    background-position: 127px -49px;
}
.inverted .hues-x-wiresbottom::before {
    background-position: 127px 0;
}

.hues-x-wiresleft::before, .hues-x-wiresbottom::before, .hues-x-wiresright::before {
    content: '';
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
}

.hues-x-visualisercontainer {
    transform: scaleY(-1);
    position: absolute;
    width: 100%;
    height: 64px;
    top: 25px;
    left: 0;
}
